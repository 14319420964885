<template>
  <div id="chart">
    <div class="text-center">
      <h3 class="font-weight-bold fill-height" v-html="status"></h3>
    </div>
    <apexchart ref="chart" type="radialBar" :height="200" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'Condition',
  props: {
    condition: {
      type: Number,
      default: 0,
      required: true,
    },
    status: {
      type: String,
      default: '&nbsp',
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: {
        series: [],
        chartOptions: {
          chart: {
            height: '200',
            type: 'radialBar',
            offsetY: 0,
            fontFamily: 'Encode Sans Semi Condensed',
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: '70%',
                background: '#2b425c',
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#fff',
                  fontSize: '13px',
                },
                value: {
                  color: '#fff',
                  fontSize: '30px',
                  show: true,
                },
              },
            },
          },
          fill: {
            colors: ['#ce003e'],
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              gradientToColors: ['#ce003e'],
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          labels: [],
        },
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.renderChart();
    });
  },
  computed: {
    options() {
      return this.chart.chartOptions;
    },
    series() {
      return this.chart.series;
    },
  },
  methods: {
    renderChart() {
      this.$refs.chart?.updateSeries([this.condition]);
      this.$refs.chart?.updateOptions({ labels: [this.label] }, true, true, true);
    },
  },
  watch: {
    condition: {
      handler() {
        this.renderChart();
      },
    },
  },
};
</script>

<style></style>
