<template>
  <div>
    <apexchart ref="chart" height="250" :options="options" :series="chart.series"> </apexchart>
  </div>
</template>

<script>
export default {
  name: 'PriceTrend',
  props: {
    trend: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return this.chart.chartOptions;
    },
    series() {
      return this.chart.series;
    },
  },
  data() {
    return {
      chart: {
        series: [
          {
            data: this.trend.prices,
          },
        ],
        chartOptions: {
          chart: {
            type: 'area',
            height: 250,
            toolbar: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            fontFamily: 'Encode Sans Semi Condensed',
          },
          noData: {
            text: 'Geçmişe ait fiyat bilgisi bulunamadı.',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#333',
              fontSize: '14px',
              fontFamily: 'Encode Sans Semi Condensed',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          labels: this.trend.dates,
          xaxis: {
            type: 'datetime',
            tickAmount: 6,
            tooltip: {
              enabled: false,
            },
            labels: {
              datetimeUTC: true,
              hideOverlappingLabels: true,
              formatter: (value) => this.$moment(value).format('DD MMM YYYY'),
              style: {
                fontSize: '10px',
                fontFamily: 'Encode Sans Semi Condensed',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              },
            },
          },
          yaxis: {
            show: true,
            labels: {
              show: true,
              align: 'right',
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: '10px',
                fontFamily: 'Encode Sans Semi Condensed',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-label',
              },
              offsetX: 0,
              offsetY: 0,
              formatter: (price) => {
                return this.$root.$options.filters.tl(price, {
                  avoidEmptyDecimals: '',
                });
              },
            },
          },
          tooltip: {
            enabled: true,
            style: {
              fontSize: '10px',
              fontFamily: 'Encode Sans Semi Condensed',
            },
            x: {
              show: true,
              format: 'dd MMM',
              formatter: (value) => this.$moment(value).format('DD MMM YYYY'),
            },
            y: {
              // eslint-disable-next-line no-unused-vars
              formatter: (price, { series, seriesIndex, dataPointIndex, w }) => {
                return this.$root.$options.filters.tl(price, {
                  avoidEmptyDecimals: '',
                });
              },
              title: {
                // eslint-disable-next-line no-unused-vars
                formatter: (seriesName) => '',
              },
            },
            marker: {
              show: false,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100],
            },
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          grid: {
            show: true,
            borderColor: '#ececec',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 20,
            },
          },
        },
      },
    };
  },
  methods: {},
  watch: {
    trend: {
      handler() {
        this.$refs.chart.updateSeries([{ data: this.trend.prices }]);
        this.$refs.chart.updateOptions({ labels: this.trend.dates }, true, true, true);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
