<template>
  <div>
    <div v-for="(prop, propIndex) in techProps" :key="`group-${propIndex}`">
      <v-row dense class="font-weight-bold grey lighten-3">
        <v-col>
          {{ prop.name }}
        </v-col>
      </v-row>
      <v-row dense v-for="(tech, techIndex) in prop.specs" :key="`tech-${techIndex}`">
        <v-col cols="6">{{ tech.name }}</v-col>
        <v-col cols="6">{{ tech.value }}</v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TechProps',
  props: {
    techProps: {
      type: Array,
      default: () => null,
    },
  },
  computed: {
    propsData() {
      return this.techProps;
    },
  },
};
</script>

<style></style>
