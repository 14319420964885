<template>
  <div class="price-offers">
    <v-dialog v-model="offerDialog" persistent max-width="250" v-if="currentOffer">
      <v-card>
        <v-card-title class="text-h5"> Talep oluştur!</v-card-title>
        <v-card-text>
          {{ currentOffer.company }} firmasının teklifi için talep oluşturmak istediğinizden emin misiniz?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize" color="red" text @click="offerDialog = false"> Vazgeç </v-btn>
          <v-btn
            class="text-capitalize"
            color="green darken-1"
            text
            :loading="loadingKey === 'apply-offer'"
            @click="$emit('apply-price-offer', currentOffer, applyOfferCb)"
          >
            Onaylıyorum
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-column align-center justify-center pt-15" v-if="isCollected === false">
      <div class="mb-2">Fiyat alınamadı. Lütfen daha sonra tekrar deneyiniz.</div>
      <v-btn :loading="isLoading" class="text-capitalize" color="#2b425c" dark @click="$emit('collect-price', cb)">
        Tekrar dene
      </v-btn>
    </div>

    <div
      class="price-offers--empty d-flex flex-column align-center justify-center"
      :class="{ 'pt-15': isEmpty }"
      v-if="isCollected !== false"
    >
      <div v-if="isEmpty" class="mb-2">Henüz teklif alınmadı</div>
      <div class="d-flex justify-end" v-if="allowOffer">
        <v-btn :loading="isLoading" class="text-capitalize" color="#2b425c" dark @click="$emit('collect-price', cb)">
          <v-icon left x-small>fas fa-handshake</v-icon> Teklif al
        </v-btn>
      </div>
    </div>
    <div v-if="!isEmpty && !isLoading">
      <!-- <div class="text-center font-weight-bold">
        {{ expire }} tarihine kadar geçerli tekliflerimiz aşağıda ki gibidir.
      </div> -->
      <v-list two-line>
        <template v-for="(offer, index) in offers">
          <v-list-item class="px-0" dense :key="offer.id">
            <template>
              <v-avatar
                class="ma-3"
                width="65"
                size="70"
                tile
                color="grey lighten-4"
                style="border: 1px solid #e1e1e1 !important"
              >
                <v-img max-width="55" :aspect-ratio="'16/9'" contain :src="offer.logo"></v-img>
              </v-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="offer.company"></v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold py-2">
                  <div :class="offer.priceAdvantage > 0 ? 'text-decoration-line-through' : 'font-weight-bold'">
                    {{ offer.price | tl({ avoidEmptyDecimals: '' }) }}
                  </div>
                  <div v-if="offer.priceAdvantage > 0" class="font-weight-bold">
                    {{ offer.priceAdvantage | tl({ avoidEmptyDecimals: '' }) }}
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div>{{ $moment.utc(offer.offerDate).format('YYYY-MM-DD') }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="offer.active">
                <v-btn
                  class="text-capitalize"
                  small
                  outlined
                  color="#2B425C"
                  @click.stop.prevent="openOfferDialog(offer)"
                  v-if="offer.trackingNo === null && offer.active"
                >
                  <v-icon x-small color="grey lighten-1" v-text="'fa-check'" left> </v-icon>
                  Talep
                </v-btn>

                <div
                  class="d-flex flex-column justify-center align-end"
                  v-if="offer.active && offer.trackingNo !== null"
                >
                  <div class="font-weight-bold">Talep No</div>
                  <div>{{ offer.trackingNo }}</div>
                </div>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-divider v-if="index < offers.length - 1" :key="index"></v-divider>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PriceOffer',
  props: {
    priceOffer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      collected: null,
      offerDialog: false,
      currentOffer: null,
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'loadingKey']),
    expire() {
      return this.$moment.utc(this.priceOffer.offerExpire).format('YYYY-MM-DD HH:mm');
    },
    allowOffer() {
      return this.priceOffer?.allowOffer;
    },
    offers() {
      return this.priceOffer?.offers;
    },
    isEmpty() {
      return this.offers?.length === 0;
    },
    isCollected() {
      return this.collected;
    },
  },
  methods: {
    cb(collected) {
      this.collected = collected;
    },
    openOfferDialog(offer) {
      this.currentOffer = offer;
      this.offerDialog = true;
    },
    applyOfferCb() {
      this.currentOffer = null;
      this.offerDialog = false;
    },
  },
};
</script>

<style></style>
