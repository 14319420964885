<template>
  <v-card flat tile class="values-container mb-2" :class="{ 'dream-car': isDreamCar }">
    <v-card-title class="pa-2 text-center justify-center white--text garage--title">
      {{ valuesCaption }}
      <v-btn
        depressed
        width="36"
        height="36"
        fab
        color="white"
        absolute
        style="left: 15px; top: 15px"
        @click="$emit('add-car')"
        v-if="hasAddLimit"
      >
        <v-icon small v-text="'fa-plus'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-center align-center">
        <v-avatar size="100" class="values-avatar d-flex flex-column">
          <v-avatar size="90" color="white" class="d-flex flex-column">
            <v-card-subtitle class="font-weight-bold pa-0"> {{ garageCount }} Araç</v-card-subtitle>
            <div>{{ totalValues | tl({ avoidEmptyDecimals: '' }) }}</div>
          </v-avatar>
        </v-avatar>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ValuesCard',
  props: {
    garageCount: {
      type: Number,
      default: 0,
      required: true,
    },
    totalValues: {
      type: Number,
      default: 0,
      required: true,
    },
    isDreamCar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['hasAddLimit']),
    valuesCaption() {
      return this.isDreamCar ? 'Hayalimdeki Araç' : 'Garajım';
    },
  },
};
</script>

<style lang="scss" scoped>
.values-container {
  background-color: #3e6b9e;
  background: -moz-radial-gradient(circle, rgba(62, 107, 158, 1) 0%, rgba(43, 55, 92, 1) 100%) !important;
  background: -webkit-radial-gradient(circle, rgba(62, 107, 158, 1) 0%, rgba(43, 55, 92, 1) 100%) !important;
  background: radial-gradient(circle, rgba(62, 107, 158, 1) 0%, rgba(43, 55, 92, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3e6b9e",endColorstr="#2b375c",GradientType=1) !important;

  .garage--title {
    text-shadow: 0px 3px 3px #333;
  }

  .values-avatar {
    background: linear-gradient(180deg, #ff0024 0%, #ebebeb 50%);
  }

  &.dream-car {
    background-image: url('https://storage.googleapis.com/leadgenb-static/super-app-assets/dream-3.png') !important;
    background-position: center !important;
    background-size: cover !important;
    background-color: #285383 !important;

    .values-avatar {
      background: linear-gradient(180deg, #b4b102 0%, #ebebeb 50%);
    }
  }
}
</style>
