<template>
  <div class="fill-height mb-2">
    <div class="fill-height d-flex justify-center align-center" v-if="!fetchComplete">
      <v-progress-circular color="primary" width="5" size="50" indeterminate style="overflow: hidden" />
    </div>

    <add-car-card @add-car="add" v-if="fetchComplete && garage.length === 0" />

    <v-dialog v-model="confirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">{{ confirmTitle }}</v-card-title>
        <v-card-text>{{ confirmText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideConfirmDialog"> Vazgeç </v-btn>
          <v-btn color="green darken-1" text :loading="isLoading" @click="confirmFunc"> Evet </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <values-card
      :garage-count="isDreamCar ? dreamsCount : carsCount"
      :total-values="isDreamCar ? dreamValues : totalValues"
      :is-dream-car="isDreamCar"
      @add-car="add"
      v-if="fetchComplete && garage.length > 0"
    />

    <div class="d-flex flex-row justify-center mb-2 garage-buttons" v-if="fetchComplete && garage.length > 0">
      <v-pagination
        v-model="page"
        :length="garage.length"
        circle
        color="#3e6b9e"
        @input="clickPagination"
      ></v-pagination>
    </div>

    <carousel
      :perPage="1"
      :scrollPerPage="true"
      :paginationPadding="5"
      :centerMode="true"
      :minSwipeDistance="50"
      :paginationEnabled="false"
      ref="carousel"
      @pageChange="pageChange"
      v-if="fetchComplete && garage.length > 0"
    >
      <slide v-for="(car, index) in garage" :key="`car-${index}`">
        <v-card class="mx-3" loader-height="100%" height="100%" flat rounded="lg" color="grey lighten-4">
          <v-card-title>
            {{ car.title }}
            <v-spacer></v-spacer>
            <v-menu
              attach=".VueCarousel"
              :absolute="true"
              close-on-click
              close-on-content-click
              min-width="100%"
              :origin="'top left'"
              content-class="car-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="grey">
                  <v-icon v-text="'fa-ellipsis-v'" @click="quickMenu = true" v-bind="attrs" v-on="on"></v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  MENU
                  <v-spacer></v-spacer>
                  <v-btn icon color="grey">
                    <v-icon v-text="'fa-times'" @click="quickMenu = false"></v-icon>
                  </v-btn>
                </v-card-title>
                <v-list dense flat nav>
                  <v-list-item-group>
                    <v-list-item :to="{ name: 'car', params: { id: car.key } }">
                      <v-list-item-content>Düzenle</v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showPriceConfirmDialog(car)">
                      <v-list-item-content>Fiyat al</v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showConfirmDialog(car)">
                      <v-list-item-content>Aracı Sil</v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="pb-0 font-weight-bold">
            {{ car.year }}
            {{ car.brand?.value }}
            {{ car.model?.value }}
            {{ car.fuelType?.value }}
            {{ car.transmissionType?.value }}
          </v-card-subtitle>
          <v-card-subtitle class="pt-0">
            {{ car.version?.value }}
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <div class="d-flex flex-row justify-space-between align-center">
              <div v-if="!isDreamCar">
                <v-icon left size="14" v-text="'fa-tachometer-alt'"></v-icon>
                {{ car.kilometer }} Km
              </div>
              <div>
                <v-icon left size="14" v-text="'fa-car'"></v-icon>
                {{ car.bodyType?.value }}
              </div>
              <div>
                <v-icon left size="14" v-text="'fa-gear'"></v-icon>
                {{ car.transmissionType?.value }}
              </div>
              <div>
                <v-icon left size="14" v-text="'fa-tachometer-alt'"></v-icon>
                {{ car.fuelType?.value }}
              </div>
            </div>
          </v-card-text>
          <v-card-text class="pa-0 price-section">
            <v-row no-gutters class="fill-height">
              <v-col cols="6">
                <div class="blue-color fill-height white--text ml-n2 pb-3 px-3 d-flex flex-column justify-center">
                  <div class="price-section--title">Parekende Satış</div>
                  <div>{{ car.price | tl({ avoidEmptyDecimals: '' }) }}</div>
                  <div v-if="!isDreamCar" class="font-weight-thin">
                    Ortalama {{ marketAvgSellingDay }} günde satılır
                  </div>
                </div>
              </v-col>
              <v-col
                cols="6"
                class="price-section--img"
                style="background-size: 160px; background-position: right"
                :style="{
                  backgroundImage: `url(${
                    car.image ?? 'https://storage.googleapis.com/leadgenb-static/super-app-assets/car.svg'
                  })`,
                }"
              >
              </v-col>
            </v-row>
          </v-card-text>
          <div v-if="!isDreamCar && car.damageEffectCost !== 0">
            <v-card-text class="damage-section d-flex align-center" style="font-size: 13px">
              <div class="font-weight-bold">
                <v-icon left size="14" v-text="'fa-car-crash'"></v-icon>
                Hasar Bilgisi
              </div>
              <v-spacer></v-spacer>
              <div
                class="d-flex flex-row flex-grow-1"
                v-if="car.paintedCount > 0 || car.scratchedCount > 0 || car.replacedCount > 0"
              >
                <div class="damage-section--info">
                  <v-icon class="mr-1" size="8" v-text="'fa-circle'" color="blue"></v-icon>{{ car.paintedCount }}B
                </div>
                <div class="damage-section--info">
                  <v-icon class="mr-1" size="8" v-text="'fa-circle'" color="green"></v-icon>{{ car.scratchedCount }}Ç
                </div>
                <div class="damage-section--info">
                  <v-icon class="mr-1" size="8" v-text="'fa-circle'" color="red"></v-icon>{{ car.replacedCount }}D
                </div>
              </div>
              <div v-else class="text-right">Araçta Hasar Yok</div>
              <!--
                <div class="red--text">
                 {{ car.damageEffectCost | tl({ avoidEmptyDecimals: '' }) }} 
                hasar bilgisi
              </div>
              -->
            </v-card-text>
            <v-divider v-if="!isDreamCar"></v-divider>
          </div>
          <v-card-text v-if="car.insurancePrice > 0">
            <div class="d-flex flex-row justify-space-between">
              <div class="font-weight-bold">
                <v-icon size="14" left v-text="'fa-shield-alt'"></v-icon>
                Kasko fiyatı
              </div>
              <div>
                {{ car.insurancePrice | tl({ avoidEmptyDecimals: '' }) }}
              </div>
            </div>
          </v-card-text>
          <v-card-text class="pa-0" v-if="!isDreamCar">
            <v-row dense no-gutters>
              <v-col cols="6" v-if="kmCondition !== null">
                <condition :condition="kmCondition" :status="kmConditionStatus" :label="'KM kondisyonu'" />
              </v-col>
              <v-col cols="6" v-if="damageCondition !== null">
                <condition :condition="damageCondition" :status="damageConditionStatus" :label="'Hasar kondisyonu'" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="px-0 pb-0">
            <v-tabs
              v-model="tab"
              height="45"
              slider-color="#9EAAB5"
              color="white"
              background-color="#2b425c"
              dark
              align-with-title
              centered
              center-active
            >
              <v-tab class="text-capitalize" :href="'#price-trend'"> Fiyat Trendi</v-tab>
              <v-tab class="text-capitalize" :href="'#price-offer'" v-if="!isDreamCar"> Teklif Topla </v-tab>
              <v-tab class="text-capitalize" :href="'#find-car'"> Otobul </v-tab>
              <v-tab class="text-capitalize" :href="'#insurance-quote'" v-if="!isDreamCar"> Sigorta Teklifi </v-tab>
              <v-tab class="text-capitalize" :href="'#credit'"> Kredi </v-tab>
              <v-tab class="text-capitalize" :href="'#tram-query'" v-if="!isDreamCar"> Tramer Sorgula </v-tab>
              <v-tab class="text-capitalize" :href="'#tech-props'"> Teknik Özellikler </v-tab>
              <v-tabs-items v-model="tab" touchless style="min-height: 250px">
                <v-tab-item value="price-trend">
                  <price-trend :trend="priceTrend" v-if="priceTrend" />
                  <div class="d-flex justify-center align-center mt-15" v-else>
                    <v-progress-circular indeterminate color="primary" size="50" width="2"></v-progress-circular>
                  </div>
                </v-tab-item>
                <v-tab-item value="price-offer" v-if="!isDreamCar">
                  <v-card-text>
                    <price-offer
                      :price-offer="priceOfferResult"
                      @collect-price="collectPrice"
                      @apply-price-offer="applyPriceOffer"
                    />
                  </v-card-text>
                </v-tab-item>
                <v-tab-item value="find-car">
                  <v-card-text> Otobul </v-card-text>
                </v-tab-item>
                <v-tab-item value="insurance-quote" v-if="!isDreamCar">
                  <v-card-text> Sigorta teklifi </v-card-text>
                </v-tab-item>
                <v-tab-item value="credit">
                  <v-card-text> Kredi </v-card-text>
                </v-tab-item>
                <v-tab-item value="tram-query" v-if="!isDreamCar">
                  <v-card-text> Tramer sorgula </v-card-text>
                </v-tab-item>
                <v-tab-item value="tech-props">
                  <v-card-text>
                    <tech-props :tech-props="carTechProps" />
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </v-card>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  FETCH_GARAGE,
  DELETE_CAR,
  CALCULATE_GARAGE_PRICE,
  FETCH_CAR_INSIDE,
  FETCH_CAR_TECH_PROPS,
  FETCH_PRICE_OFFERS,
  COLLECT_PRICE_OFFERS,
  APPLY_OFFER,
} from '@/store/modules/garage.module';
import { Carousel, Slide } from 'vue-carousel';
import PriceTrend from './components/PriceTrend.vue';
import ValuesCard from './components/ValuesCard.vue';
import AddCarCard from './components/AddCarCard.vue';
import Condition from './components/Condition.vue';
import TechProps from './components/TechProps.vue';
import PriceOffer from './components/PriceOffer.vue';
export default {
  name: 'MyCars',
  components: {
    Carousel,
    Slide,
    PriceTrend,
    ValuesCard,
    AddCarCard,
    Condition,
    TechProps,
    PriceOffer,
  },
  data() {
    return {
      confirmTitle: '',
      confirmText: '',
      confirmDialog: false,
      confirmFunc: () => {},
      markedToBeConfirm: null,
      tab: null,
      quickMenu: false,
      fetchComplete: false,
      currentPage: null,
      dreamCar: false,
      page: 1,
    };
  },
  created() {
    this.$nextTick(() => {
      this.fetchGarage().finally(() => {
        setTimeout(() => {
          this.fetchComplete = true;
        }, 1000);

        this.currentPage = this.$refs.carousel?.currentPage ?? 0;
        this.checkDreamCar();
      });
    });
  },
  computed: {
    ...mapGetters(['isLoading', 'garage', 'garageCount', 'dreamsCount', 'carsCount', 'totalValues', 'dreamValues']),
    currentCar() {
      return this.garage[this.currentPage];
    },
    damageCondition() {
      return this.carInside?.damageCondition;
    },
    kmCondition() {
      return this.carInside?.kmCondition;
    },
    kmConditionStatus() {
      return this.carInside?.kmConditionStatus;
    },
    damageConditionStatus() {
      return this.carInside?.damageConditionStatus;
    },
    marketAvgSellingDay() {
      return this.carInside?.marketAvgSellingDay;
    },
    carouselCurrentPage() {
      return this.$refs.carousel?.currentPage ?? 0;
    },
    isDreamCar() {
      return this.dreamCar;
    },
    computedCurrentPage() {
      return this.currentPage;
    },
    priceTrend() {
      return this.currentCar?.priceTrend;
    },
    carInside() {
      return this.currentCar?.carInside;
    },
    carTechProps() {
      return this.currentCar?.carTechProps;
    },
    priceOfferResult() {
      return this.currentCar?.priceOfferResult;
    },
  },
  methods: {
    ...mapActions({
      fetchGarage: FETCH_GARAGE,
      removeItem: DELETE_CAR,
      calculatePrice: CALCULATE_GARAGE_PRICE,
      fetchCarInside: FETCH_CAR_INSIDE,
      fetchCarTechProps: FETCH_CAR_TECH_PROPS,
      fetchPriceOffers: FETCH_PRICE_OFFERS,
      collectPriceOffers: COLLECT_PRICE_OFFERS,
      applyOffer: APPLY_OFFER,
    }),
    clickPagination(index) {
      this.$refs.carousel.goToPage(index - 1);
    },
    pageChange(index) {
      this.currentPage = index;
      this.page = index + 1;
      this.checkDreamCar();
    },
    checkDreamCar() {
      this.dreamCar = this.garage[this.currentPage]?.isDreamCar ?? false;
    },
    add() {
      this.$router.push({ name: 'car' });
    },
    remove() {
      let index = this.garage.findIndex((x) => x.key === this.markedToBeConfirm.key);
      this.removeItem(this.markedToBeConfirm).then(() => {
        this.hideConfirmDialog();

        this.page = index === 0 ? 1 : index;
        this.clickPagination(index);
      });
    },
    getPrice() {
      const payload = {
        car: this.markedToBeConfirm,
        date: this.$moment().format(),
      };

      this.calculatePrice(payload).then(() => {
        this.hideConfirmDialog();
      });
    },
    showConfirmDialog(car) {
      this.confirmTitle = 'Aracı sil';
      this.confirmText = 'Bu aracı silmek istediğinizden emin misiniz?';
      this.confirmFunc = this.remove;
      this.markedToBeConfirm = car;
      this.confirmDialog = true;
    },
    showPriceConfirmDialog(car) {
      this.confirmTitle = 'Araca fiyat al';
      this.confirmText = 'Bu araca fiyat almak istediğinizden emin misiniz?';
      this.confirmFunc = this.getPrice;
      this.markedToBeConfirm = car;
      this.confirmDialog = true;
    },
    hideConfirmDialog() {
      this.markedToBeConfirm = null;
      this.confirmDialog = false;
    },
    getCarInside() {
      if (this.carInside !== null) return;
      this.fetchCarInside(this.currentCar);
    },
    getTechProps() {
      if (this.carTechProps !== null) return;
      this.fetchCarTechProps(this.currentCar);
    },
    getPriceOffers() {
      if (this.priceOffers?.length > 0) return;
      this.fetchPriceOffers(this.currentCar);
    },
    collectPrice(cb) {
      this.collectPriceOffers(this.currentCar).then((collected) => {
        if (collected) this.getPriceOffers();
        cb(collected);
      });
    },
    applyPriceOffer(offer, cb) {
      const offerId = offer.id;
      this.applyOffer(offer).then((trackingNumber) => {
        const offer = this.currentCar?.priceOfferResult.offers.find((x) => x.id === offerId);
        if (offer) offer.trackingNo = trackingNumber;
        cb();
      });
    },
  },
  watch: {
    tab: {
      handler() {
        if (this.tab === 'price-offer') {
          this.getPriceOffers();
        } else if (this.tab === 'tech-props') {
          this.getTechProps();
        }
      },
    },
    currentPage: {
      handler() {
        this.tab = null;
        this.getCarInside();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .v-pagination {
    &__item,
    &__navigation {
      height: 30px !important;
      margin: 0.2rem;
      min-width: 30px !important;
      font-size: 0.8rem;
      font-weight: bold;
      color: #fff !important;
      background-color: #d42525 !important;

      .v-icon {
        color: #fff !important;
      }
      &--active {
        background-color: #2b375c !important;
      }
    }
  }

  .VueCarousel {
    justify-content: space-between;
    .VueCarousel-slide {
      width: 100% !important;
      height: 100% !important;
    }

    .VueCarousel-inner {
      flex-basis: 100% !important;
      height: 100% !important;
      // margin-right: 30px;
    }

    .VueCarousel-wrapper {
      height: 100% !important;
    }

    &-dot-container {
      margin-top: 0px !important;
    }
  }

  .v-slide-group {
    &__prev {
      &--disabled {
        display: none !important;
      }
    }
  }

  .car-menu {
    height: 100%;
    top: 0px !important;
    left: 0px !important;
  }

  .garage-buttons {
    .carousel-top-btn {
      background-color: #3e6b9e !important;
      color: #fff !important;
      font-weight: bold;

      &--active {
        background-color: #ce003e !important;
      }
    }
  }
}

.price-section {
  height: 87px;
  .blue-color {
    background-image: url('https://storage.googleapis.com/leadgenb-static/super-app-assets/prc-bg.png');
    background-size: cover;
    background-position: 0px 0px;
    background-size: 184px 87px;
  }
  &--img {
    background-repeat: no-repeat;
    background-position: right center;
  }
}
.damage-section {
  &--info {
    margin-right: 10px;
    &:last-child {
      margin-right: 0px !important;
    }
  }
}
</style>
