<template>
  <div class="fill-height d-flex flex-column justify-center align-center">
    <div class="mb-10">
      <v-img
        max-width="200"
        src="https://storage.googleapis.com/leadgenb-static/super-app-assets/undraw_by_my_car_re_j3jt.svg"
        contain
      ></v-img>
    </div>
    <v-btn outlined class="text-capitalize" color="primary" @click="$emit('add-car')">
      <v-icon small left color="primary" v-text="'fa-plus'"></v-icon>
      Yeni Araç Ekle
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AddCarCard',
};
</script>

<style></style>
